<template>
  <Popup
    title="지도 정보 수정"
    :maxWidth="800"
    closeBtnText="닫기"
    compeleteBtnText="저장"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="onClickPopupComplete"
  >
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px" />
        <col />
        <col style="width:160px" />
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>title</th>
          <td colspan="3">{{ data.title }}</td>
        </tr>
        <tr>
          <th>mapStyle</th>
          <td>{{ data.mapStyle }}</td>
          <th>mapType</th>
          <td>{{ data.mapType }}</td>
        </tr>
        <tr>
          <th>보상주체명</th>
          <td colspan="3">
            <Input placeholder="스폰서" :value.sync="data.sponsorDto.name" />
          </td>
        </tr>
        <tr>
          <th>보상주체 이미지</th>
          <td colspan="3">
            <FileUpload
              :dataList.sync="data.sponsorDto.imageSponsorList"
              :canMultiple="false"
              :acceptList="['png', 'jpg', 'jpeg']"
              :size="[60, 60]"
              sizeType="FIT"
              :saveTrigger="sponsorImageSaveTrigger"
              :isSaved.sync="sponsorImageIsSaved"
            />
          </td>
        </tr>
        <tr>
          <th>최대 혜택</th>
          <td>
            <Input placeholder="2,500원" :value.sync="data.rewardTitle" />
          </td>
          <th>용돈외의 보상타입</th>
          <td>
            <Selectbox
              :dataList="'map_extra_reward_type' | getSelectDataList"
              :value.sync="data.extraRewardType"
              maxWidth="174px"
            />
          </td>
        </tr>
        <tr v-if="data.extraRewardType === 'COUPON'">
          <th>쿠폰명</th>
          <td>
            <Input
              placeholder="퍼핀 쿠폰"
              :value.sync="data.rewardDescription"
            />
          </td>
          <th>쿠폰 잔여 여부</th>
          <td>
            <SwitchCheck
              :value.sync="data.useExtraReward"
              text="잔여"
              offText="소진"
            />
          </td>
        </tr>
        <tr>
          <th>맵 섬네일 이미지</th>
          <td colspan="3">
            <FileUpload
              :dataList.sync="data.thumbnailImageUrlList"
              :canMultiple="false"
              :acceptList="['png', 'jpg', 'jpeg']"
              sizeType="FIT"
              :saveTrigger="thumbnailSaveTrigger"
              :isSaved.sync="thumbnailIsSaved"
            />
          </td>
        </tr>
        <tr>
          <th>소개페이지 url</th>
          <td colspan="3">
            <Input placeholder="https://" :value.sync="data.introductionUrl" />
          </td>
        </tr>
        <tr>
          <th rowspan="2">URL</th>
          <td colspan="3">
            <div class="group_form">
              <Selectbox
                :dataList="makeUrl.projectEnvList"
                :value.sync="makeUrl.projectEnv"
                maxWidth="174px"
                @update:value="onUpdateMakeUrl()"
              />
              <Selectbox
                :dataList="makeUrl.userAgentList"
                :value.sync="makeUrl.userAgent"
                maxWidth="174px"
                @update:value="onUpdateMakeUrl()"
              />
              <Button
                btnSize="small"
                btnStyle="secondary_border"
                text="만들기"
                @onClickBtn="onClickMakeUrl()"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" style="height:136px">
            <div v-if="makeUrl.makeUrlResult" class="group_form txt_break_all">
              <span class="txt_tbl item_form_fill">{{
                makeUrl.makeUrlResult
              }}</span>
              <Button
                btnSize="xsmall"
                btnStyle="secondary_border"
                text="copy"
                @onClickBtn="
                  viewModel.onClickCopy(
                    makeUrl.makeUrlResult,
                    `${makeUrl.projectEnv} ${makeUrl.userAgent} map url`,
                  )
                "
              />
            </div>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

export default {
  name: 'MapDetailPopup',
  components: {
    Popup,
    TableView,
    Input,
    Selectbox,
    SwitchCheck,
    FileUpload,
    Button,
  },
  props: {
    viewModel: Object,
    currentDataViewModel: Object,
  },
  data() {
    return {
      makeUrl: {
        projectEnvList: [
          { id: 'dev', text: 'DEV' },
          { id: 'production', text: 'PROD' },
        ],
        userAgentList: [
          { id: 'ANDROID', text: 'Android' },
          { id: 'IOS', text: 'iOS' },
          { id: 'WEB', text: 'Web' },
        ],
        projectEnv: 'dev',
        userAgent: 'ANDROID',
        makeUrlResult: '',
      },
      thumbnailSaveTrigger: false,
      thumbnailIsSaved: false,
      sponsorImageSaveTrigger: false,
      sponsorImageIsSaved: false,
    };
  },
  computed: {
    data() {
      return this.currentDataViewModel.MAP.detailData;
    },
    mapUrl() {
      return `/world-map?mapUid=${this.data.uid}&mapStyle=${this.data.mapStyle}&mapType=${this.data.mapType}`;
    },
  },
  watch: {
    thumbnailIsSaved() {
      if (this.thumbnailIsSaved) {
        this.checkFileUpload();
      }
    },
    sponsorImageIsSaved() {
      if (this.sponsorImageIsSaved) {
        this.checkFileUpload();
      }
    },
  },
  methods: {
    onUpdateMakeUrl() {
      this.makeUrl.makeUrlResult = '';
    },
    onClickMakeUrl() {
      this.makeUrl.makeUrlResult = this.getDeepLink(
        this.mapUrl,
        this.makeUrl.projectEnv,
        this.makeUrl.userAgent,
      );
    },
    getDeepLink(targetUrl, projectEnv, userAgent) {
      const isProduction = projectEnv === 'production';
      const devAppScheme = !isProduction && userAgent === 'IOS' ? '-beta' : '';

      const deviceDeepLinkFront = `firfin${devAppScheme}://home/`;
      const deepLinkBack = 'web?targetServer=CONTENT&fullScreen=true&url=';

      const devUrl = isProduction ? '' : '.dev';

      const targetUrlOrigin = `https://content${devUrl}.lemontree.ai`;

      const targetUrlEncoding = encodeURIComponent(targetUrlOrigin + targetUrl);
      const redirectUrl = `https://api-gw.lemontree.ai/content-api/pages/landing-page?url=${targetUrlEncoding}`;
      const redirectUrlBase64Encoding = btoa(redirectUrl);
      console.log('getDeepLink userAgent', userAgent);
      if (userAgent === 'WEB') {
        console.log('targetUrlOrigin', '+', targetUrl);
        return targetUrlOrigin + targetUrl;
      } else {
        return `${deviceDeepLinkFront}${deepLinkBack}${redirectUrlBase64Encoding}`;
      }
    },
    onClickPopupComplete() {
      const isThumbailUpload = Boolean(
        this.data.thumbnailImageUrlList.length > 0 &&
          this.data.thumbnailImageUrlList[0].file,
      );
      const isSponsorImageUpload = Boolean(
        this.data.sponsorDto.imageSponsorList.length > 0 &&
          this.data.sponsorDto.imageSponsorList[0].file,
      );

      this.thumbnailSaveTrigger = isThumbailUpload;
      this.sponsorImageSaveTrigger = isSponsorImageUpload;

      if (!isThumbailUpload && !isSponsorImageUpload) {
        this.onCompleteFileSave();
      }
    },
    checkFileUpload() {
      var fileVaild = true;
      if (this.thumbnailSaveTrigger && !this.thumbnailIsSaved)
        fileVaild = false;
      if (this.sponsorImageSaveTrigger && !this.sponsorImageIsSaved)
        fileVaild = false;
      if (fileVaild) {
        this.onCompleteFileSave();
      }
    },
    onCompleteFileSave() {
      this.$emit('onClickPopupComplete', this.data.getData());
    },
  },
};
</script>
