import MapModel from '@/views/worldMap/contentFolder/model/map/MapModel';
import PlaceModel from '@/views/worldMap/contentFolder/model/place/PlaceModel'
import CourseModel from '@/views/worldMap/contentFolder/model/course/CourseModel'
import QuestModel from '@/views/worldMap/contentFolder/model/quest/QuestModel'
import SubjectModel from '@/views/worldMap/contentFolder/model/subject/SubjectModel'

import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class ContentFolderDataViewModel {
  constructor() {
    this.MAP = {
      dataList: [],
      searchData:{
        searchParams: {
          direction: 'ASC',
          pageSize: 200,
        },
        value:'',
      },
      paginationData: {
        totalCount:0,
      },
      value: null,
      detailData: new MapModel(),
    }
    this.PLACE = {
      dataList: [],
      searchData:{
        searchParams: {
          direction: 'ASC',
          pageSize:200,
        },
        value:'',
      },
      paginationData: {
        totalCount:0,
      },
      value:null,
      detailData: new PlaceModel(),
    }
    this.COURSE = {
      dataList: [],
      searchData:{
        searchParams: {
          direction: 'ASC',
          sortProperty: 'position'
        },
        value:'',
      },
      paginationData: {
        totalCount:0,
      },
      value:null,
      detailData: new CourseModel(),
    }
    this.QUEST = {
      type:{
        dataList:[
          { id:'QUIZ', text:'퀴즈', count:0 },
          { id:'MISSION', text:'미션', count:0 },
        ],
        value:'QUIZ'
      },
      QUIZ:{
        dataList: [],
        searchData:{
          searchParams: {
            direction: 'ASC',
            sortProperty: 'position'
          },
          value:'',
        },
        paginationData: {
          totalCount:0,
        },
        value:null,
        detailData: new QuestModel(),
      },
      MISSION: {
        dataList: [],
        searchData:{
          searchParams: {
            direction: 'ASC',
          },
          value:'',
        },
        paginationData: {
          totalCount:0,
        },
        value:null,
        detailData: new QuestModel(),
      },
    }
    this.SUBJECT = {
      QUIZ:{
        dataList: [],
        searchData:{
          searchParams: {
            direction: 'ASC',
            sortProperty: 'position'
          },
          value:'',
        },
        paginationData: {
          totalCount:0,
        },
        value:null,
        detailData: new SubjectModel(),
      },
      MISSION: {
        dataList: [],
        searchData:{
          searchParams: {
            direction: 'ASC',
          },
          value:'',
        },
        paginationData: {
          totalCount:0,
        },
        value:null,
        detailData: new SubjectModel(),
      },
    }
  }

  setCurrentData(currentData){
    const { PLACE, COURSE, QUEST, SUBJECT } = JSON.parse(JSON.stringify(currentData));
    console.log('setCurrentData',PLACE)

    // place
    this.PLACE.dataList = PLACE.dataList;
    this.PLACE.value = PLACE.value;

    // course
    this.COURSE.dataList = COURSE.dataList;
    this.COURSE.value = COURSE.value;
    if( COURSE.detailData.id ){
      this.COURSE.detailData.setData(COURSE.detailData.objRaw);
    }else{
      this.COURSE.detailData.setCurrentPlaceId(this.PLACE.value)
    }

    // quest - type
    this.QUEST.type.value = QUEST.type.value;

    // quest - quiz
    this.QUEST.QUIZ.dataList = QUEST.QUIZ.dataList;
    this.QUEST.QUIZ.value = QUEST.QUIZ.value;
    if( QUEST.QUIZ.detailData.id ){
      this.QUEST.QUIZ.detailData.setData(QUEST.QUIZ.detailData.objRaw);
    }else{
      this.QUEST.QUIZ.detailData.setCurrentData(this.PLACE.value, this.COURSE.value)
    }

    // quest - mission
    this.QUEST.MISSION.dataList = QUEST.MISSION.dataList;
    this.QUEST.MISSION.value = QUEST.MISSION.value;
    if( QUEST.MISSION.detailData.id ){
      this.QUEST.MISSION.detailData.setData(QUEST.MISSION.detailData.objRaw);
    }else{
      this.QUEST.MISSION.detailData.setCurrentData(this.PLACE.value, this.COURSE.value)
    }

    // subject - quiz
    this.SUBJECT.QUIZ.dataList = SUBJECT.QUIZ.dataList;
    this.SUBJECT.QUIZ.value = SUBJECT.QUIZ.value;
    if( SUBJECT.QUIZ.detailData.id ){
      this.SUBJECT.QUIZ.detailData.setData(SUBJECT.QUIZ.detailData.objRaw);
    }else{
      this.SUBJECT.QUIZ.detailData.setCurrentData(this.PLACE.value, this.COURSE.value, this.QUEST[QUEST.type.value].value, QUEST.type.value)
    }

    // subject - mission
    this.SUBJECT.MISSION.dataList = SUBJECT.MISSION.dataList;
    this.SUBJECT.MISSION.value = SUBJECT.MISSION.value;
    if( SUBJECT.MISSION.detailData.id ){
      this.SUBJECT.MISSION.detailData.setData(SUBJECT.MISSION.detailData.objRaw);
    }else{
      this.SUBJECT.MISSION.detailData.setCurrentData(this.PLACE.value, this.COURSE.value, this.QUEST[QUEST.type.value].value, QUEST.type.value)
    }
  }

  onClickContentReceipt(isCheck){
    const requestCallBack = () => {
      this.postSubjectCheck(isCheck);
    }
    const checkAlertMsg = '<strong>검수완료 처리할까요?</strong>릴리즈 가능함을 표시하는 기능입니다.<br>검수완료 표시하고 문서를 잠글까요?<br>(어드민 역할만 수정가능)';
    const uncheckAlertMsg = '<strong>검수완료 처리를 해제할까요?</strong>문서가 잠금해제되고(모든 사용자가 수정가능)<br>검수필요 상태로 되돌립니다.';
    const alertMsg = isCheck ? checkAlertMsg : uncheckAlertMsg;
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: alertMsg,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestCallBack,
    });
  }

  resetDetilModel(modelId){
    switch(modelId){
      case 'MAP': {
        // this.MAP.value = '';
        this.MAP.detailData = new MapModel();
        break;
      }
      case 'PLACE': {
        // this.PLACE.value = '';
        this.PLACE.detailData = new PlaceModel();
        break;
      }
      case 'COURSE': {
        // this.COURSE.value = '';
        this.COURSE.detailData = new CourseModel();
        break;
      }
      case 'QUEST': {
        const questType = this.QUEST.type.value;
        // this.QUEST[questType].value = '';
        this.QUEST[questType].detailData = new QuestModel();
        break;
      }
      case 'SUBJECT': {
        const questType = this.QUEST.type.value;
        // this.SUBJECT[questType].value = '';
        this.SUBJECT[questType].detailData = new SubjectModel();
        break;
      }
    }
  }

  onClickSubjectPageChange(id){
    this.getSubjectData(id)
  }

  // --- map ---
  getMapList(){
    const query = makeQueryStringByObject(this.MAP.searchData.searchParams);
    const path = `${apiPath.MAP_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.MAP.dataList = resultData.maps;
      // if(isSetCourse){
        const value = resultData.maps[0].id;
        this.MAP.value = value;
        this.getPlaceList(value);
      // }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // map - 상태변경
  putMapStatus(data,status,callBack){
    const path = `${apiPath.MAP_STATUS.format(data.id)}`;
    const body = {
      "status": status
    }
    PUT_REQUEST(path,body).then(
    (success) => {
      const resultData = success.data;
      this.getMapList();
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // map - 상세 수정
  putMap(data,callBack){
    const path = `${apiPath.MAP.format(data.id)}`;
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.getMapList();
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // getMapData(data,callBack){
  //   this.MAP.detailData.setData(data);
  //   if(callBack){
  //     callBack();
  //   }
  // }

  // --- place ---
  getPlaceList(mapId){
    const query = makeQueryStringByObject(this.PLACE.searchData.searchParams);
    const path = `${apiPath.PLACE_LIST.format(mapId)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      const selectedDataList = resultData.data.map(item => {
        let rObj = {};
        rObj.id = item.id;
        rObj.text = `${item.name} (${item.engName})`;
        rObj.name = item.name;
        rObj.engName = item.engName;
        rObj.stage = item.stage
        return rObj;
      });
      this.PLACE.dataList = selectedDataList;
      // if(isSetCourse){
        // const value = selectedDataList[0].id;
        // this.PLACE.value = value;
        // this.getCourseList(value);
      // }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // place - 상세
  getPlaceData(data,callBack){
    const path = `${apiPath.PLACE}/${data.id}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.PLACE.detailData.setData(resultData);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // place - 등록
  postPlaceData(data){
    const path = `${apiPath.PLACE}`;
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '장소가 추가되었습니다.');
      this.getPlaceList();
      this.resetPlaceModel();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // place - 수정
  putPlaceData(data){
    const path = `${apiPath.PLACE}/${data.id}`;
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '장소가 수정되었습니다.');
      this.getPlaceList();
      this.resetPlaceModel();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // place - 삭제
  deletePlaceData(id){
    const path = `${apiPath.PLACE_DELETE}/${id}`;
    DELETE_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '장소가 삭제되었습니다.');
      this.getPlaceList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // --- course ---
  // course - 목록
  getCourseList(placeId){
    const query = makeQueryStringByObject(this.COURSE.searchData.searchParams);
    const path = `${apiPath.COURSE_LIST}/${placeId}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.COURSE.value = null;
      this.COURSE.dataList = resultData.data;
      this.COURSE.paginationData.totalCount = resultData.total;
      this.QUEST.type.value = this.QUEST.type.dataList[0].id;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })   
  }
  // course - 상세
  getCourseData(id, callBack){
    const path = `${apiPath.COURSE}/${id}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.COURSE.detailData.setData(resultData);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // course - 등록
  postCourseData(data, callBack){
    const path = `${apiPath.COURSE}`;
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '코스가 추가되었습니다.');
      if(this.PLACE.value != data.placeId){
        this.PLACE.value = data.placeId
      }
      this.getCourseList(data.placeId);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // course - 수정
  putCourseData(data, callBack){
    const path = `${apiPath.COURSE}/${data.id}`;
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '코스가 수정되었습니다.');
      if(this.PLACE.value != data.placeId){
        this.PLACE.value = data.placeId
      }
      this.getCourseList(data.placeId);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // course - 순서 수정
  putCoursePositionData(positionData){
    const path = apiPath.CORSE_POSITION.format(positionData.id);
    const data = { newPosition: positionData.newIndex, oldPosition: positionData.oldIndex };
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `코스 순서가 변경되었습니다.`);
      this.getCourseList(this.PLACE.value);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // course - 삭제
  deleteCourseData(id){
    const path = `${apiPath.COURSE_DELETE}/${id}`;
    DELETE_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '코스가 삭제되었습니다.');
      this.getCourseList(this.PLACE.value);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // --- quest ---
  // quest - 목록
  getQuestList(courseId){
    const questType = this.QUEST.type.value;
    // console.log('getQuestList',courseId,questType)
    const query = makeQueryStringByObject(this.QUEST[questType].searchData.searchParams);
    const path = `${apiPath.QUEST_LIST}/${courseId}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.data;
      this.QUEST.type.dataList.forEach(typeItem => {
        const typeFilterResult = resultData.filter(resultDataItem => resultDataItem.quizType === typeItem.id);
        const totalCount = typeFilterResult.length;
        this.QUEST[typeItem.id].dataList = typeFilterResult;
        this.QUEST[typeItem.id].paginationData.totalCount = totalCount;
        typeItem.count = totalCount;
      })
      this.QUEST[questType].value = null;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })   
  }
  // quest - 상세
  getQuestData(id, callBack){
    const questType = this.QUEST.type.value;
    const path = `${apiPath.QUEST}/${id}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      resultData.placeId = this.PLACE.value;
      this.QUEST[questType].detailData.setData(resultData);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // quest - 등록
  postQuestData(data, callBack){
    const path = `${apiPath.QUEST_REGISTER}`;
    // let data = this.questModel.getRegisterData();
    // let lesson = this.lessonModel.getRegisterData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '퀘스트가 추가되었습니다.');
      if(this.PLACE.value != data.placeId){
        this.PLACE.value = data.placeId;
      }
      if(this.COURSE.value != data.course_id){
        this.COURSE.value = data.course_id;
      }
      this.getQuestList(data.course_id);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // quest - 수정
  putQuestData(data, callBack){
    const path = `${apiPath.QUEST}/${data.id}`;
    // const data = this.questModel.getModifyData();
    // let lesson = this.lessonModel.getRegisterData();
    // if(data.lessonPosition == 'NONE'){
    //   data.lesson = {};
    // }
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '퀘스트가 수정되었습니다.');
      if(this.PLACE.value != data.placeId){
        this.PLACE.value = data.placeId;
      }
      if(this.COURSE.value != data.courseId){
        this.COURSE.value = data.courseId;
      }
      this.getQuestList(data.courseId);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // quest - 순서 수정
  putQuestPositionData(positionData){
    const path = apiPath.QUEST_POSITION.format(positionData.id);
    const data = { newPosition: positionData.newIndex, oldPosition: positionData.oldIndex };
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '퀘스트 순서가 변경되었습니다.');
      this.getQuestList(this.COURSE.value);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // quest - 삭제
  deleteQuestData(id){
    const path = `${apiPath.QUEST_DELETE}/${id}`;
    DELETE_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '퀘스트가 삭제되었습니다.');
      this.getQuestList(this.COURSE.value);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // --- subject ---
  // subject - 목록
  getSubjectList(questId){
    const questType = this.QUEST.type.value;
    const query = makeQueryStringByObject(this.SUBJECT[questType].searchData.searchParams);
    const path = `${apiPath.QUIZ_LIST}/${questId}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.SUBJECT[questType].value = null;
      this.SUBJECT[questType].dataList = resultData.data;
      this.SUBJECT[questType].paginationData.totalCount = resultData.total;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // subject - 상세
  getSubjectData(id, callBack){
    const questType = this.QUEST.type.value;
    const path = `${apiPath.QUIZ}/${id}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      resultData.placeId = this.PLACE.value;
      resultData.courseId = this.COURSE.value;
      this.SUBJECT[questType].detailData.setData(resultData);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // subject - 등록
  postSubjectData(data, callBack){
    const path = `${apiPath.QUIZ}`;
    // let data = this.questModel.getRegisterData();
    // let lesson = this.lessonModel.getRegisterData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '퀴즈가 추가되었습니다.');
      this.getSubjectList(data.questId);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  resetSubjectList(quizId,resultData){
    const questType = this.QUEST.type.value;

    const newDataList = this.SUBJECT.QUIZ.dataList;
    const subjectFolderIndex = newDataList.findIndex(item => item.id === quizId);
    newDataList[subjectFolderIndex] = resultData;

    this.SUBJECT[questType].value = null;
    this.SUBJECT[questType].detailData.setData(resultData);
    this.SUBJECT[questType].dataList = newDataList;
    this.SUBJECT[questType].paginationData.totalCount = resultData.total;
  }
  // subject - 수정
  putSubjectData(data, callBack, id){
    const path = `${apiPath.QUIZ}/${id}`;
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '퀴즈가 수정되었습니다.');
      this.resetSubjectList(id,resultData);
      if(callBack){
        callBack();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // subject - 순서 수정
  putSubjectPositionData(positionData){
    const path = apiPath.QUIZ_POSITION.format(positionData.id);
    const data = { newPosition: positionData.newIndex, oldPosition: positionData.oldIndex };
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '퀴즈 순서가 변경되었습니다.');
      this.getSubjectList(this.QUEST[this.QUEST.type.value].value);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // subject - 삭제
  deleteSubjectData(id){
    const path = `${apiPath.QUIZ_DELETE}/${id}`;
    DELETE_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '퀴즈가 삭제되었습니다.');
      this.getSubjectList(this.QUEST[this.QUEST.type.value].value);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // subject - 검수
  postSubjectCheck(isCheck){
    const quizId = this.SUBJECT.QUIZ.value;
    const check_path = `${apiPath.QUIZ_CHECKED.format(quizId)}`;
    const uncheck_path = `${apiPath.QUIZ_UNCHECKED.format(quizId)}`;
    const path = isCheck ? check_path : uncheck_path;
    const desc = isCheck ? '검수처리가 완료되었습니다' : '검수완료 처리가 해제되었습니다';
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', desc);
      this.resetSubjectList(quizId,resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}