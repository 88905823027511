<template>
  <Popup
    :title="data.id ? `미션 콘텐츠 수정` : `새로운 미션 콘텐츠 추가`"
    :maxWidth="1000"
    closeBtnText="닫기"
    compeleteBtnText="저장"
    :isDimmedClose="false"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="$emit('onClickPopupComplete',data.getData())">
    <!-- :isDisabeldCompeleteBtn="viewModel.contentRegisterValidatedResult()" -->
    <!-- <template v-slot:headRight>
      <DetailPagination
        :prevId="viewModel.prevContentId"
        :nextId="viewModel.nextContentId"
        :page="viewModel.selectContentIndex"
        :totalPage="viewModel.contentsData.CONTENT.dataList.length"
        @onClickPage="id => viewModel.onClickContentModify(id)"/>
      <button
        v-if="isPreviewShow"
        class="btn_preview_close"
        @click.stop="onClickPreview(false)">
        <IconSvg iconName="cross" :size="20" iconColor="#fff"/>
      </button>
    </template>
    <template v-slot:footBtn>
      <Button
        class="btn_preview"
        btnSize="small"
        btnStyle="secondary"
        text="미리보기"
        @onClickBtn="onClickPreview(true)"/>
    </template> -->
    <div class="group_content">
      <div :class="['area_preview',{ active: isPreviewShow }]">
        <div class="head_preview">
          <strong class="tit_preview">미리보기</strong>
          <!-- <p class="desc_preview">{{ previewPageDesc }}</p> -->
        </div>
        <div class="body_preview">
          <div class="box_phone">
            <!-- <div class="pagination_comm">
              <span class="item_pagination active"></span>
              <span class="item_pagination"></span>
              <span class="item_pagination"></span>
            </div> -->
            <strong class="tit_intro">{{ data.quizContents.guideTitle || '가이드 타이틀을 입력해주세요' }}</strong>
            <p class="desc_intro" v-html="$options.filters.convertLineFeed(data.quizContents.guideContents,'가이드 본문을 입력해주세요')"></p>
            <div v-if="data.quizContents.outlinkName && data.quizContents.outlinkUrl" class="area_bottom">
              <div class="group_answer">
                <button class="btn_answer">{{ data.quizContents.outlinkName }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area_form">
        <InfoDosc
          v-if="data.id"
          :status="data.status"
          statusConvertIdToColor="contents_status"
          :dataList="data.historyDataList"/>
        <TableView>
          <template v-slot:tbl_colgroup>
            <col style="width:116px"/>
            <col style="width:100px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>퀘스트<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(상위폴더)</span></th>
              <td colspan="2">
                <div class="group_form">
                  <Selectbox
                    :dataList="dataViewModel.PLACE.dataList"
                    :isDisabled="isDisabledQuest"
                    :value="data.placeId"
                    placeholder="장소선택"
                    :showLine="9"
                    @update:value="value => onUpdatePlace(value)"/>
                  <span class="txt_center">></span>
                  <Selectbox
                    :dataList="dataViewModel.COURSE.dataList | setSelectDataList('id', 'name')"
                    :isDisabled="isDisabledQuest || !data.placeId"
                    :value="data.courseId"
                    :placeholder="!data.placeId ? '장소를 선택해주세요':'코스선택'"
                    :showLine="9"
                    @update:value="value => onUpdateCourse(value)"/>
                  <span class="txt_center">></span>
                  <Selectbox
                    :dataList="dataViewModel.QUEST.MISSION.dataList | setSelectDataList('id', 'name')"
                    :isDisabled="isDisabledQuest || !data.courseId"
                    :value.sync="data.questId"
                    :placeholder="!data.courseId ? '코스를 선택해주세요':'퀘스트선택'"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>미션 타입<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
              <td colspan="2">
                <Radio
                  :dataList="quizContentTypeDataList"
                  :value.sync="data.quizContentType"/>
                  <!-- @update:value="onUpdateQuizContetnType()" -->
              </td>
            </tr>
            <tr>
              <th rowspan="3">가이드<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
              <th>타이틀</th>
              <td>
                <Input
                  placeholder="가이드 타이틀"
                  :value.sync="data.quizContents.guideTitle"/>
              </td>
            </tr>
            <tr>
              <th>본문</th>
              <td>
                <Textarea
                  placeholder="가이드 본문"
                  :value.sync="data.quizContents.guideContents"
                  :max="100"
                  :showLine="3"
                  :isCount="true"/>
              </td>
            </tr>
            <tr>
              <th>첨부자료</th>
              <td>파일첨부</td>
            </tr>
            <tr>
              <th rowspan="2">아웃링크</th>
              <th>버튼명</th>
              <td>
                <Input
                  placeholder="아웃링크 버튼명"
                  :value.sync="data.quizContents.outlinkName"/>
              </td>
            </tr>
            <tr>
              <th>url</th>
              <td>
                <Input
                  placeholder="아웃링크 url"
                  :value.sync="data.quizContents.outlinkUrl"/>
              </td>
            </tr>
            <tr>
              <th rowspan="2">역량 점수<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
              <th>배점</th>
              <td>
                <Radio
                  :dataList="quizGradeDataList"
                  :value.sync="data.quizContents.grade"/>
              </td>
            </tr>
            <tr>
              <th>역량</th>
              <td>
                <Radio
                  :dataList="contentQuizAreaDataList"
                  :value.sync="data.quizArea"/>
              </td>
            </tr>
            <tr>
              <th>메타정보<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
              <td colspan="2">
                <Radio
                  :dataList="contentApplyCountryDataList"
                  :value.sync="data.applyCountry"/>
              </td>
            </tr>
            <tr>
              <th>금융태그</th>
              <td colspan="2">
                <TagRegister :dataList.sync="data.tags" />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import DetailPagination from '@/views/worldMap/contentFolder/view/components/DetailPagination';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import InfoDosc from '@lemontree-ai/lemontree-admin-common-front/components/common/infoDosc/InfoDosc';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import TagRegister from '@lemontree-ai/lemontree-admin-common-front/components/common/tag/TagRegister';
// import { Swiper, SwiperSlide, Pagination } from 'vue-awesome-swiper'
import { getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'
import ContentFolderDataViewModel from '@/views/worldMap/contentFolder/viewModel/ContentFolderDataViewModel'

export default {
  name:'SubjectMissionRegisterPopup',
  components:{
    Popup,
    DetailPagination,
    TableView,
    InfoDosc,
    Button,
    IconSvg,
    Selectbox,
    Radio,
    Input,
    Textarea,
    TagRegister,

    // Swiper,
    // SwiperSlide,
    // Pagination,
  },
  props:{
    currentDataViewModel: Object,
    validData: Object,
  },
  // mounted(){
  //   if(this.data.id){
  //     this.selectOneOptions = this.data.quizContents.options.map(item=>{
  //       let rObj = { id:'', text:'' };
  //       rObj.id = item;
  //       rObj.text = item;
  //       return rObj;
  //     })
  //   }else{
  //     this.onUpdateQuizContetnType();
  //   }

  //   // if(this.$refs.previewSwiper && this.$refs.previewSwiper.$swiper){
  //   //   this.isInit = true;
  //   // }
  // },
  beforeMount(){
    this.dataViewModel.setCurrentData(this.currentDataViewModel);
  },
  data(){
    return{
      dataViewModel: new ContentFolderDataViewModel(),
      isPreviewShow:false,
      // isInit: false,
      // realIndex:0,
      // swiperOption: {
      //   slidesPerView: 1,
      //   spaceBetween: 0,
      //   speed:100,
      //   mousewheel:{
      //     forceToAxis:true,
      //   },
      //   pagination: {
      //     el: '.swiper-product-pagination',
      //     type: 'bullets',
      //     clickable: true
      //   },
      // },
    }
  },
  computed:{
    data(){
      return this.dataViewModel.SUBJECT.MISSION.detailData
    },
    quizContentTypeDataList(){
      return getSelectDataList('mission_quizContentType');
    },
    quizGradeDataList(){ // 배점
      return getSelectDataList('mission_grade');
    },
    contentQuizAreaDataList(){ // 역량
      return getSelectDataList('content_quizArea');
    },
    contentApplyCountryDataList(){ // 메타정보
      return getSelectDataList('content_applyCountry');
    },
    isDisabledQuest(){
      return Boolean(this.data.id && this.data.status != 'DRAFT')
    },
    // previewPageDesc(){
    //   switch(this.realIndex){
    //     case 0 : { return '가이드' }
    //     case 1 : { return '문제와 보기' }
    //     case 2 : { return '정답과 해설 - 정답인 경우' }
    //     case 3 : { return '정답과 해설 - 오답인 경우' }
    //   }
    // }
  },
  methods:{
    onUpdatePlace(value){
      console.log('onUpdatePlace',this.data.placeId,value)
      if(this.data.placeId != value){
        this.data.placeId = value;
        this.dataViewModel.getCourseList(value);
        this.data.courseId = null;
        this.data.questId = null;
      }
    },
    onUpdateCourse(value){
      console.log('onUpdateCourse',this.data.courseId,value)
      if(this.data.courseId != value){
        this.data.courseId = value;
        this.dataViewModel.getQuestList(value);
        this.data.questId = null;
      }
    },
    // onUpdateQuizContetnType(){
    //   if(this.data.quizContentType === 'OX'){
    //     this.data.quizContents.options = this.quizOXDataList.map(item => item.text);
    //   }else{
    //     this.onUpdateSelectOneOptions();
    //   }
    //   this.data.quizContents.answer = '';
    // },
    // slideChange(){
    //   this.realIndex = this.$refs.previewSwiper.$swiper.realIndex;
    // },
    onClickPreview(val){
      this.isPreviewShow = val;
    }
  }
}
</script>
<style scoped>
.group_form .selectbox_comm{width:calc(33.3% - 20px)}

.btn_pagination{padding:6px 10px}
.btn_pagination::v-deep .icon_lemontree{margin:0 0 2px 0}
.txt_page{display:inline-block;margin:0 14px;font-size:14px;line-height:38px;vertical-align:top}

/* .layer_body{overflow:hidden} */
.group_content:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.area_form{overflow:hidden;border-right:1px solid #ccc}
.area_form .info_docs{margin-top:0}
.area_preview{float:right;width:415px;height:872px;box-sizing:border-box}
/* .tbl_comm{overflow:hidden;margin-right:415px;border-right:1px solid #ccc}
.area_preview{position:absolute;right:24px;width:415px;height:872px;box-sizing:border-box} */
.btn_preview{display:none}
.btn_preview_close{display:none;position:fixed;top:0;right:0;padding:20px 30px;z-index:40}

.head_preview{padding:20px 20px 10px;line-height:20px;}
.head_preview .tit_preview{display:inline-block;font-weight:800;font-size:18px;vertical-align:top}
.head_preview .desc_preview{display:inline-block;margin-left:10px;font-size:16px;vertical-align:top}

.swiper-container{height:100%}
.body_preview{height:100%;padding:10px 20px;box-sizing:border-box}
.box_phone{position:relative;overflow:hidden;width:100%;max-width:375px;height:100%;max-height:812px;padding:60px 30px 30px;border-radius:40px;background-color:#1C0D34;box-sizing:border-box;box-shadow:2px 2px 8px 2px rgba(0,0,0,0.4)}
.box_phone *{user-select:none;-moz-user-select:none;-webkit-user-select:none;-ms-user-select:none}
.tit_intro{display:block;font-weight:800;font-size:22px;line-height:32px;color:#fff;text-align:center;word-wrap:break-word;word-break:keep-all}
.desc_intro{margin-top:40px;font-weight:bold;font-size:16px;line-height:20px;color:#fff;text-align:center;word-wrap:break-word;word-break:keep-all}

.tit_quiz{display:block;font-weight:800;font-size:22px;line-height:32px;color:#fff;text-align:center;word-wrap:break-word;word-break:keep-all}
.area_bottom{position:absolute;bottom:0;left:0;right:0}
.group_answer{padding:0 30px 40px}
.btn_answer{display:block;width:100%;padding:14px 20px;border-radius:18px;font-weight:bold;font-size:18px;line-height:26px;background-color:rgba(255,255,255,.8);color:#222}
.btn_answer + .btn_answer{margin-top:16px}

.box_explan{padding:30px 30px 40px;color:#fff}
.tit_explan{display:block;font-weight:bold;font-size:16px;line-height:18px}
.txt_answer{display:block;margin-top:8px;font-weight:800;font-size:22px;line-height:24px}
.desc_explan{margin-top:20px;font-weight:bold;font-size:14px;line-height:20px}
.box_correct{background-color:#111}
.box_wrong{background-color:#F04848}

.pagination_comm{overflow:hidden;margin-bottom:40px;height:3px;box-sizing:border-box}
.item_pagination{display:inline-block;width:33.333%;height:3px;margin:0;border-radius:0;background-color:#fff;opacity:0.4;vertical-align:top}
.item_pagination.active{opacity:1}
.item_pagination:first-child{border-radius:2px 0 0 2px}
.item_pagination:last-child{border-radius:0 2px 2px 0}

/* 모바일 */
@media all and (max-width:1000px){
  .tbl_comm{border-right:0 none}
  .btn_preview,
  .btn_preview_close{display:inline-block;vertical-align:top}

  .area_preview{display:none;position:fixed;top:0;left:0;right:0;bottom:0;z-index:20;width:100%;height:100%;background-color:rgba(0,0,0,.86)}
  .area_preview.active{display:block}
  
  .head_preview{display:none}
  .swiper-slide{padding:0;font-size:0;white-space:nowrap;text-align:center}
  .swiper-slide:after{display:inline-block;height:100%;vertical-align:middle;content:''}
  .box_phone{display:inline-block;vertical-align:middle;white-space:normal;border-radius:0}
  .area_form{border-right:0 none}
  /* .area_form .info_docs  + .tbl_comm{margin-top:20px} */
}
/* 모바일 */
@media all and (max-width:600px){
  .btn_pagination{width:30px;height:30px;margin:3px 0;padding:2px 6px}
  .txt_page{margin:3px 8px;line-height:32px}
}
</style>