<template>
  <Popup
    :title="data.id ? `퀴즈 콘텐츠 수정` : `새로운 퀴즈 콘텐츠 추가`"
    :maxWidth="1300"
    closeBtnText="닫기"
    compeleteBtnText="저장"
    :isDimmedClose="false"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="onClickPopupComplete()">
    <!-- :isDisabeldCompeleteBtn="ContentFolderDataViewModel.contentRegisterValidatedResult()" -->
    <template v-slot:headRight>
      <DetailPagination
        :prevId="detailPaginagtionData.prevId"
        :nextId="detailPaginagtionData.nextId"
        :page="detailPaginagtionData.page"
        :totalPage="currentDataViewModel.SUBJECT.QUIZ.paginationData.totalCount"
        @onClickPage="id => dataViewModel.onClickSubjectPageChange(id)"/>
      <!-- <button
        v-if="isPreviewShow"
        class="btn_preview_close"
        @click.stop="onClickPreview(false)">
        <IconSvg iconName="cross" :size="20" iconColor="#fff"/>
      </button> -->
    </template>
    <div class="group_content">
      <ContentPreview
        :key="detailPaginagtionData.page"
        :isPreviewShow="isPreviewShow"
        :swiperIndex.sync="swiperIndex"
        :previewData="previewData"
        :previewDataList="previewDataList"
        :deviceDataList="deviceDataList"
        :previewDevice.sync="previewDevice"
        :overflowScrollDataList="overflowScrollDataList"
        @onClickOverflowError="item => onClickOverflowError(item)"
        ref="contentPreview" />
      <div class="area_form">
        <InfoDosc
          v-if="data.id"
          :status="data.status"
          statusConvertIdToColor="contents_status"
          :dataList="data.historyDataList"/>
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">기본정보</strong>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:124px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr v-if="data.id">
              <th>검수상태</th>
              <td :class="data.checked ? 'tc_green' : 'tc_red'">
                <IconSvg v-if="data.checked" iconName="check" :size="14" iconColor="#fff" bgcolor="#388E3B" />
                <IconSvg v-else iconName="cross" :size="12" iconColor="#fff" bgcolor="#F04848" />
                {{ data.checked ? '검수완료' : '검수필요' }}
                <div class="area_right">
                  <Button
                    v-if="data.id && !data.checked"
                    btnSize="small"
                    btnStyle="primary_border"
                    text="검수완료 처리"
                    @onClickBtn="dataViewModel.onClickContentReceipt(true)"/>
                  <Button
                    v-if="data.id && data.checked"
                    btnSize="small"
                    btnStyle="fourthly_border"
                    text="검수완료 처리 해제"
                    @onClickBtn="dataViewModel.onClickContentReceipt(false)"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>퀘스트<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(상위폴더)</span></th>
              <td>
                <div class="group_form">
                  <Selectbox
                    :dataList="dataViewModel.PLACE.dataList"
                    :isDisabled="isDisabledQuest"
                    :value="data.placeId"
                    placeholder="장소선택"
                    :showLine="9"
                    @update:value="value => onUpdatePlace(value)"/>
                  <span class="txt_center">></span>
                  <Selectbox
                    :dataList="dataViewModel.COURSE.dataList | setSelectDataList('id', 'name')"
                    :isDisabled="isDisabledQuest || !data.placeId"
                    :value="data.courseId"
                    :placeholder="!data.placeId ? '장소를 선택해주세요':'코스선택'"
                    :showLine="9"
                    @update:value="value => onUpdateCourse(value)"/>
                  <span class="txt_center">></span>
                  <Selectbox
                    :dataList="dataViewModel.QUEST.QUIZ.dataList | setSelectDataList('id', 'name')"
                    :isDisabled="isDisabledQuest || !data.courseId"
                    :value.sync="data.questId"
                    :placeholder="!data.courseId ? '코스를 선택해주세요':'퀘스트선택'"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>퀴즈 타입<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
              <td>
                <Radio
                  :dataList="quizContentTypeDataList"
                  :value.sync="data.quizContentType"
                  @update:value="onUpdateData(1,'onUpdateQuizContetnType')"/>
              </td>
            </tr>
          </template>
        </TableView>
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">인트로</strong>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:124px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>인트로</th>
              <td>
                <!-- <Textarea
                  placeholder="인트로"
                  :value.sync="quizData.intro_contents"
                  :max="100"
                  :showLine="3"
                  :isCount="true"/> -->
                <EditorTiptap
                  :value.sync="data.quizContents.intro_contents"
                  :menuDataList="['highlight']"
                  @update="onUpdateData(0)"/>
              </td>
            </tr>
          </template>
        </TableView>
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">문제</strong>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:124px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>첨부자료</th>
              <td>
                <FileUpload
                  :dataList.sync="data.quizContents.content_attachment_list"
                  :canMultiple="false"
                  :size="[1440, 810]"
                  :acceptList="['jpg','jpeg','png']"
                  :saveTrigger="saveTrigger"
                  :isSaved.sync="isSaved"
                  @update:dataList="onUpdateData(1,false,true); onUpdateContentAttachement();"/>
              </td>
            </tr>
            <tr>
              <th>첨부자료<br>출처<IconSvg v-if="data.quizContents.content_attachment_list.length > 0" iconName="essential" :size="6" iconColor="#F04848"/></th>
              <td>
                <div class="group_form">
                  <span class="txt_tbl">출처 : </span>
                  <Input
                    placeholder="첨부자료 등록시 출처를 적어주세요"
                    :isDisabled="data.quizContents.content_attachment_list.length === 0"
                    :value.sync="data.quizContents.content_attachment_origin"
                    @update:value="onUpdateData(1,false,true)" />
                </div>
              </td>
            </tr>
            <tr>
              <th>텍스트<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(최대 100자)</span></th>
              <td>
                <EditorTiptap
                  :value.sync="data.quizContents.contents"
                  :menuDataList="['highlight']"
                  :maxLength="100"
                  @update="onUpdateData(1,false,true)"/>
              </td>
            </tr>
          </template>
        </TableView>
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">선택지와 정답</strong>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:124px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <template v-if="data.quizContentType === 'OX'">
              <tr>
                <th>1번 보기<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/></th>
                <td>
                  <Input
                    placeholder="1번 보기"
                    :isDisabled="true"
                    :value="quizOXDataList[0].text"/>
                </td>
              </tr>
              <tr>
                <th>2번 보기<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/></th>
                <td>
                  <Input
                    placeholder="2번 보기"
                    :isDisabled="true"
                    :value="quizOXDataList[1].text"/>
                </td>
              </tr>
              <tr>
                <th>정답체크<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/></th>
                <td>
                  <Radio
                    :dataList="quizOXDataList"
                    :value.sync="data.quizContents.answer"
                    @update:value="onUpdateData(1)"/>
                </td>
              </tr>
            </template>
            <template v-if="data.quizContentType === 'SELECTONE'">
              <tr>
                <th>1번 보기<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(최대 14자)</span></th>
                <td>
                  <Input
                    placeholder="1번 보기"
                    :value.sync="selectOneOptionsSync[0].text"
                    :maxLength="14"
                    @update:value="onUpdateData(1,'onUpdateSelectOneOptions')"/>
                </td>
              </tr>
              <tr>
                <th>2번 보기<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(최대 14자)</span></th>
                <td>
                  <Input
                    placeholder="2번 보기"
                    :value.sync="selectOneOptionsSync[1].text"
                    :maxLength="14"
                    @update:value="onUpdateData(1,'onUpdateSelectOneOptions')"/>
                </td>
              </tr>
              <tr>
                <th>정답체크<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/></th>
                <td>
                  <Radio
                    :dataList="selectOneOptionsSync"
                    :value.sync="answerSync"
                    @update:value="onUpdateData(1)"/>
                </td>
              </tr>
            </template>
            <template v-if="data.quizContentType === 'ALL'">
              <tr>
                <th>1번 보기<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(최대 14자)</span></th>
                <td>
                  <Input
                    placeholder="1번 보기"
                    :value.sync="selectOneOptionsSync[0].text"
                    :maxLength="14"
                    @update:value="onUpdateData(1,'onUpdateSelectOneOptions')"/>
                </td>
              </tr>
              <tr>
                <th>2번 보기<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(최대 14자)</span></th>
                <td>
                  <Input
                    placeholder="2번 보기"
                    :value.sync="selectOneOptionsSync[1].text"
                    :maxLength="14"
                    @update:value="onUpdateData(1,'onUpdateSelectOneOptions')"/>
                </td>
              </tr>
              <tr>
                <th>정답체크<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/></th>
                <td>
                  <RadioItem
                    name="answer1"
                    id="answer1"
                    :text="selectOneOptionsSync[0].text"
                    :item="selectOneOptionsSync[0]"
                    :isDisabled="true"
                    value="answer1"/>
                  <RadioItem
                    name="answer2"
                    id="answer2"
                    :text="selectOneOptionsSync[1].text"
                    :item="selectOneOptionsSync[1]"
                    :isDisabled="true"
                    value="answer2"/>
                </td>
              </tr>
            </template>
            <tr>
              <th>해설<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(최대 100자)</span></th>
              <td>
                <!-- <Textarea
                  placeholder="해설"
                  :value.sync="data.quizContents.comments"
                  :max="100"
                  :showLine="5"
                  :isCount="true"/> -->
                <EditorTiptap
                  :value.sync="data.quizContents.comments"
                  :menuDataList="['highlight']"
                  :maxLength="100"
                  @update:value="onUpdateData(2,false,true)"/>
              </td>
            </tr>
          </template>
        </TableView>
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">역량 점수</strong>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:124px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>배점<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/></th>
              <td>
                <Radio
                  :dataList="quizGradeDataList"
                  :value.sync="data.quizContents.grade"/>
              </td>
            </tr>
            <tr>
              <th>역량<IconSvg v-if="!isDisabledQuest" iconName="essential" :size="6" iconColor="#F04848"/></th>
              <td>
                <Radio
                  :dataList="contentQuizAreaDataList"
                  :value.sync="data.quizArea"/>
              </td>
            </tr>
          </template>
        </TableView>
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">메타정보</strong>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:124px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <!-- <tr>
              <th>메타정보<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
              <td>
                <Radio
                  :dataList="contentApplyCountryDataList"
                  :value.sync="data.applyCountry"/>
              </td>
            </tr> -->
            <tr>
              <th>금융태그</th>
              <td>
                <TagRegister :dataList.sync="data.tags" />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import DetailPagination from '@/views/worldMap/contentFolder/view/components/DetailPagination';
import ContentPreview from '@/views/worldMap/contentFolder/view/components/ContentPreview';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import InfoDosc from '@lemontree-ai/lemontree-admin-common-front/components/common/infoDosc/InfoDosc';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import RadioItem from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/RadioItem';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import EditorTiptap from '@lemontree-ai/lemontree-admin-common-front/components/common/editor/EditorTiptap';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import TagRegister from '@lemontree-ai/lemontree-admin-common-front/components/common/tag/TagRegister';
import { Swiper, SwiperSlide, Pagination } from 'vue-awesome-swiper'
import { getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'
import ContentFolderDataViewModel from '@/views/worldMap/contentFolder/viewModel/ContentFolderDataViewModel'
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin'

export default {
  name:'SubjectQuizRegisterPopup',
  mixins:[FileSaveMixin],
  components:{
    Popup,
    DetailPagination,
    ContentPreview,
    TableView,
    InfoDosc,
    Button,
    IconSvg,
    Selectbox,
    Radio,
    RadioItem,
    Input,
    Textarea,
    EditorTiptap,
    FileUpload,
    TagRegister,

    Swiper,
    SwiperSlide,
    Pagination,
  },
  props:{
    currentDataViewModel: Object,
    validData: Object,
  },
  beforeMount(){
    this.dataViewModel.setCurrentData(this.currentDataViewModel);
  },
  mounted(){
    if(this.$refs.previewSwiper && this.$refs.previewSwiper.$swiper){
      this.isInit = true;
    }
  },
  data(){
    return{
      dataViewModel: new ContentFolderDataViewModel(),
      selectOneOptions:[
        { id:1, text:'1번 보기' },
        { id:2, text:'2번 보기' },
      ],
      isPreviewShow:false,
      swiperIndex: 0,
      deviceDataList:[
        { id:'GalaxyS8', text:'Galaxy S8', size:{ width: 360, height: 740 } },
        { id:'GalaxyS20Ultra', text:'Galaxy S20 Ultra', size:{ width: 412, height: 915 } },
        { id:'iPhoneX', text:'iPhone X', size:{ width: 375, height: 812 } },
        { id:'iPhoneSE', text:'iPhone SE', size:{ width: 320, height: 568 } },
      ],
      previewDevice:'GalaxyS8',
      overflowScrollDataList:[],

      placeId: null,
      courseId: null,
      courseDataList : [],
      questDataList : [],
    }
  },
  // beforeMount(){
  //   this.placeId = this.viewModel.selectPlaceId;
  //   this.courseId = this.viewModel.selectCourseId;
  //   this.setCourseList(this.viewModel.contentsData.COURSE.dataList);
  //   this.setQuestList(this.viewModel.contentsData.QUEST.filterData[this.viewModel.selectedTabId].dataList);
  //   if(this.contentData.id){
  //     this.selectOneOptions = this.data.quizContents.options.map(item=>{
  //       let rObj = { id:'', text:'' };
  //       rObj.id = item;
  //       rObj.text = item;
  //       return rObj;
  //     })
  //   }else{
  //     this.onUpdateQuizContetnType();
  //   }
  // },
  // mounted(){
  //   this.onUpdateScroll(1);
  //   this.onUpdateScroll(3);
  // },
  // watch:{
  //   'contentData.id':{
  //     deep:true,
  //     handler(newVal, oldVal){
  //       if(newVal){
  //         this.selectOneOptions = this.data.quizContents.options.map(item=>{
  //           let rObj = { id:'', text:'' };
  //           rObj.id = item;
  //           rObj.text = item;
  //           return rObj;
  //         })
  //       }else{
  //         this.onUpdateQuizContetnType();
  //       }
  //       this.overflowScrollDataList = [];
  //       this.onUpdateScroll(1);
  //       this.onUpdateScroll(3);
  //       // this.goSwiperPage(0);
  //     },
  //   }
  // },
  computed:{
    data(){
      return this.dataViewModel.SUBJECT.QUIZ.detailData
    },
    detailPaginagtionData(){
      const quizData = this.dataViewModel.SUBJECT.QUIZ;
      const dataList = quizData.dataList;
      if(!this.data.id){
        return { page : dataList.length +1 }
      }
      const currentIndex = dataList.findIndex(item => item.id === this.data.id);
      const lastIndex = dataList.length - 1;
      let result = {
        prevId : null,
        nextId : null,
        page : currentIndex +1
      };
      if(dataList.length > 1){
        if(currentIndex === 0){
          result.prevId = dataList[lastIndex].id;
          result.nextId = dataList[currentIndex+1].id;
        }else if(currentIndex === lastIndex){
          result.prevId = dataList[currentIndex - 1].id;
          result.nextId = dataList[0].id;
        }else{
          result.prevId = dataList[currentIndex - 1].id;
          result.nextId = dataList[currentIndex+1].id;
        }
        return result;
      }else{
        return result;
      }
    },
    // contentData(){
    //   return this.viewModel.contentModel;
    // },
    // quizData(){
    //   return this.viewModel.quizModel;
    // },
    selectOneOptionsSync:{
      get(){
        if(this.data.quizContents && this.data.quizContents.options.length > 0){
          const options1 = this.data.quizContents.options[0] || '1번 보기';
          const options2 = this.data.quizContents.options[1] || '2번 보기';
          return [
            { id:options1, text:options1 },
            { id:options2, text:options2 },
          ]
        }else{
          return this.selectOneOptions
        }
      },
      set(val){
        this.data.quizContents.options[0] = val[0].text || '';
        this.data.quizContents.options[1] = val[1].text || '';
      }
    },
    answerSync:{
      get(){
        const val = this.selectOneOptionsSync.find(item => item.text === this.data.quizContents.answer);
        return val ? val.id : '';
      },
      set(val){
        this.data.quizContents.answer = this.selectOneOptionsSync.find(item => item.id === val).text;
      }
    },
    quizContentTypeDataList(){
      return getSelectDataList('quiz_quizContentType');
    },
    quizOXDataList(){ // 퀴즈 ox
      return getSelectDataList('quiz_ox');
    },
    quizGradeDataList(){ // 배점
      return getSelectDataList('quiz_grade');
    },
    contentQuizAreaDataList(){ // 역량
      return getSelectDataList('content_quizArea');
    },
    contentApplyCountryDataList(){ // 메타정보
      return getSelectDataList('content_applyCountry');
    },
    isDisabledQuest(){
      return Boolean(this.data.id && this.data.status != 'DRAFT')
    },
    // isImg(){
    //   return this.viewModel.quizModel.content_attachment_list.length > 0;
    // },
    previewData(){
      // if(this.viewModel.selectContentIndex === null && this.contentData.id === null){
      //   introQuizNum = totalQuizSize+1
      // }
      return {
        paginationData: {
          page: this.detailPaginagtionData.page,
          total: this.currentDataViewModel.SUBJECT.QUIZ.paginationData.totalCount
        },
        quizContent: this.dataViewModel.SUBJECT.QUIZ.detailData.quizContents,
        quizContentType: this.dataViewModel.SUBJECT.QUIZ.detailData.quizContentType,
        // options: this.viewModel.quizModel.options,
        // answer: this.viewModel.quizModel.answer,
      }
    },
    previewDataList(){
      return [
        {
          quizPageMode: 'Intro',
          // quizPageTitle:'오늘의 퀴즈#'+this.previewData.paginationData.page,
          quizPageTitle:'',
          isContMiddle: true,
          isShowAnswer: false,
        },
        {
          quizPageMode: 'Question',
          // quizPageTitle:'오늘의 퀴즈#'+this.previewData.paginationData.page,
          quizPageTitle:'',
          isContMiddle: this.isImg ? false : true,
          isActive: true,
          isShowAnswer: true,
        },
        // {
        //   quizPageMode: 'Result',
        //   // quizPageTitle:'오늘의 퀴즈#'+this.previewData.paginationData.page,
        //   quizPageTitle:'',
        //   isContMiddle: true,
        //   confettiDataList: this.confettiDataList,
        //   animationduration: this.animationduration,
        //   isCorrect:true,
        //   quizPrice:'300',
        //   isShowAnswer: true,
        // },
        {
          quizPageMode: 'Explanation',
          // quizPageTitle:'오늘의 퀴즈#'+this.previewData.paginationData.page,
          quizPageTitle:'',
          isContMiddle: true,
          isShowAnswer: false,
        },
      ]
    }
  },
  methods:{
  //   getCourseList(){
  //     const query = makeQueryStringByObject(this.viewModel.contentsData.COURSE.searchParams);
  //     const path = `${this.$apiPath.COURSE_LIST}/${this.placeId}/${query}`;
  //     GET_REQUEST(path).then(
  //     (success) => {
  //       const resultData = success.data;
  //       this.setCourseList(resultData.data)
  //     }, (failed) => {
  //       this.$store.dispatch('commonToast/fetchToastStart', failed.msg);
  //     })   
  //   },
  //   getQuestList(){
  //     const query = makeQueryStringByObject(this.viewModel.contentsData.QUEST.searchParams);
  //     const path = `${this.$apiPath.QUEST_LIST}/${this.courseId}${query}`;
  //     GET_REQUEST(path).then(
  //     (success) => {
  //       const resultData = success.data;
  //       this.setQuestList(resultData.data)
  //     }, (failed) => {
  //       this.$store.dispatch('commonToast/fetchToastStart', failed.msg);
  //     })
  //   },
  //   setCourseList(data){
  //     let result = [];
  //     result = data;
  //     result = result.map(item => {
  //       let rObj = {};
  //       rObj.id = item.id;
  //       rObj.text = `${item.name}`;
  //       return rObj;
  //     });
  //     this.courseDataList = result;
  //   },
  //   setQuestList(data){
  //     let result = [];
  //     result = data;
  //     result = result.map(item => {
  //       let rObj = {};
  //       rObj.id = item.id;
  //       rObj.text = `${item.name}`;
  //       return rObj;
  //     });
  //     this.questDataList = result;
  //   },
    onUpdateQuizContetnType(isInit){
      if(this.data.quizContentType === 'OX'){
        this.data.quizContents.options = this.quizOXDataList.map(item => item.text);
      }else{
        this.onUpdateSelectOneOptions();
      }
      this.data.quizContents.answer = '';
      this.onUpdateScroll(1);
    },
    onUpdateSelectOneOptions(){
      this.data.quizContents.options = this.selectOneOptionsSync.map(item => item.text);
    },
  //   onClickPreview(val){
  //     this.isPreviewShow = val;
  //   },
    onUpdatePlace(value){
      if(this.data.placeId != value){
        this.data.placeId = value;
        this.dataViewModel.getCourseList(value);
        this.data.courseId = null;
        this.data.questId = null;
      }
    },
    onUpdateCourse(value){
      console.log('onUpdateCourse',this.data.courseId,value)
      if(this.data.courseId != value){
        this.data.courseId = value;
        this.dataViewModel.getQuestList(value);
        this.data.questId = null;
      }
    },
    onUpdateScroll(targetPageIndex){
      this.$nextTick(() => {
        let target = '';
        let targetPageName = '';
        switch(targetPageIndex){
          case 1 : {
            target = 'Question';
            targetPageName = '문제';
            break;
          }
          case 2 : {
            target = 'Explanation';
            targetPageName = '해설';
            break;
          }
        }
        // let result = [];
        // result = result.concat(this.overflowScrollDataList.filter(item => item.id != target));
        // console.log('concat',result);
        this.deviceDataList.forEach(deviceItem => {
          const targetPageLayout = this.$refs.contentPreview.$refs[deviceItem.id].filter(item => item._props.quizPageMode === target);
          // let targetPageHeight =  targetPageLayout[0].getContentHeight()
          this.getContentHeight(target,targetPageLayout).then((resolve) => {
            let targetPageHeight = resolve;
            // console.log(deviceItem.id,target,deviceItem.size.height, targetPageHeight)
            let rObj = {
              id: target,
              index: targetPageIndex,
              deviceId: deviceItem.id,
              deviceName: deviceItem.text,
              target: targetPageName,
              // height: targetPageHeight,
            }
            this.overflowScrollDataList = this.overflowScrollDataList.filter(item => {
              return item.id != rObj.id || item.deviceId != rObj.deviceId;
            });
            if(deviceItem.size.height < targetPageHeight){
              this.overflowScrollDataList.push(rObj);
            }
          })
        });
      })
    },
    async getContentHeight(target,targetPageLayout){
      return new Promise((resolve, reject) => {
        const { quizHeader, quizContent, quizAnswer } = targetPageLayout[0].$refs;
        const quizFooterHeight = target === 'Question' ? quizAnswer.getAnswerHeight() : 0;
        quizContent.children[0].__vue__.$nextTick(() => {
          if(target === 'Question' && this.isImg && !quizContent.children[0].__vue__.$refs.imgQuiz.complete){
            quizContent.children[0].__vue__.$refs.imgQuiz.onload = () => {
              resolve(quizHeader.offsetHeight + quizContent.children[0].offsetHeight + quizFooterHeight || 0);
            };
          }else{
            resolve(quizHeader.offsetHeight + quizContent.children[0].offsetHeight + quizFooterHeight || 0);
          }
        });
      });
    },
    onClickOverflowError(overflowItem){
      this.previewDevice = overflowItem.deviceId;
      this.goSwiperPage(overflowItem.index)
    },
    onUpdateData(targetPageIndex,event,isCheckScroll){
      if(event){
        this[event]();
      }
      // if(isCheckScroll){
      //   this.onUpdateScroll(targetPageIndex)
      // }
      this.goSwiperPage(targetPageIndex)
    },
    onUpdateContentAttachement(){
      const hasContentAttachement = this.data.quizContents.content_attachment_list.length > 0;
      this.data.quizContents.content_attachment_type = hasContentAttachement ? 'img' : '';
      if(!hasContentAttachement){
        this.data.quizContents.content_attachment_origin = '';
      }
    },
    goSwiperPage(index){
      this.swiperIndex = index;
    },
    onClickPopupComplete(){
      if(this.data.quizContents.content_attachment_list.length > 0 && this.data.quizContents.content_attachment_list[0].file){
        this.onSave();
      }else{
        this.onCompleteFileSave();
      }
    },
    onCompleteFileSave(){
      this.$emit('onClickPopupComplete',this.data.getData())
    }
  }
}
</script>
<style scoped>
.tbl_view .icon_check,
.tbl_view .icon_cross{margin:9px 4px 9px 0;border-radius:3px}
.tbl_view .icon_check{padding:3px;}
.tbl_view .icon_cross{padding:4px;}

.group_form .selectbox_comm{width:calc(33.3% - 20px)}


/* .layer_body{overflow:hidden} */
.group_content:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.area_form{margin-right:460px;margin-bottom:-40px;padding-bottom:40px;border-right:1px solid #ccc}
/* ㄴ overflow:hidden;overflow-y:auto;height:calc(100vh - 237px - 60px); */
.area_form .info_docs{margin-top:0}
.area_form .tbl_comm:first-child{margin-top:20px}
.area_form .info_docs + .tbl_comm:first-child{margin-top:0}
/* .tbl_comm{overflow:hidden;margin-right:415px;border-right:1px solid #ccc}
.area_preview{position:absolute;right:24px;width:415px;height:872px;box-sizing:border-box} */
.btn_preview{display:none}
.btn_preview_close{display:none;position:fixed;top:0;right:0;padding:20px 30px;z-index:40}

/* 모바일 */
@media all and (max-width:1000px){
  .tbl_comm{border-right:0 none}
  .btn_preview,
  .btn_preview_close{display:inline-block;vertical-align:top}

  /* .area_form .info_docs  + .tbl_comm{margin-top:20px} */
  .area_form{overflow:inherit;height:auto;margin:0;border-right:0 none}
}
</style>